<template lang="pug">
.container.mt-28.mb-28.px-10
  h1.mb-3.text-2xl.font-bold.text-blue-600.text-center Qué dicen de nosotros
  h2.mb-16.text-4xl.font-black.text-gray-700.text-center "Fácil, intuitiva y rápida!"
  .flex.w-full.relative
    .buttons.prev
      .bg-green-400.cursor-pointer.rounded.w-8.h-8.flex.justify-center.items-center.text-white(
        @click="$refs.buttonPrev.click()"
      )
        BaseCustomIcon(nameIcon="mdiChevronLeft", :size="28")
    .buttons.next
      .bg-green-400.cursor-pointer.rounded.w-8.h-8.flex.justify-center.items-center.text-white(
        @click="$refs.buttonNext.click()"
      ) 
        BaseCustomIcon(nameIcon="mdiChevronRight", :size="28")

    swiper(
      :slides-per-view="1",
      :space-between="0",
      :grabCursor="false",
      :loop="true",
      :loopAdditionalSlides="5",
      ref="mySwiperRef",
      :navigation="navigation",
      :breakpoints="{ 768: { slidesPerView: 2 }, 1024: { slidesPerView: 3 } }"
    )
      swiper-slide
        cardComment(
          image="review_1.png",
          name="Joseph Pattison",
          position="Analista",
          text="Sin lugar a dudas, un gran éxito para mí. En 15 minutos creé mi CV y lo mandé a través del programa de correo electrónico."
        )
      swiper-slide
        cardComment(
          image="review_2.png",
          name="Alberto Gonzalez",
          position="Desarrollador",
          text="¡Recibí comentarios muy positivos sobre mi CV! Encontré un buen trabajo bastante rápido. Definitivamente los recomendaré."
        )
      swiper-slide
        cardComment(
          image="review_3.png",
          name="Estrella Martinez",
          position="Infermera",
          text="Muy práctico poder organizar todos mis CVs y solicitudes en un solo lugar con CVDesign. ¡Las plantillas son preciosas!"
        )
      swiper-slide
        cardComment(
          image="review_3.png",
          name="Estrella Martinez",
          position="Infermera",
          text="Muy práctico poder organizar todos mis CVs y solicitudes en un solo lugar con CVDesign. ¡Las plantillas son preciosas!"
        )
      swiper-slide
        cardComment(
          image="review_3.png",
          name="Estrella Martinez",
          position="Infermera",
          text="Muy práctico poder organizar todos mis CVs y solicitudes en un solo lugar con CVDesign. ¡Las plantillas son preciosas!"
        )
      //- span(slot="container-start")
      //-   .bg.container-start
      //- span(slot="container-end")
      //-   .bg.container-end
      .swiper-button-prev(ref="buttonPrev", slot="button-prev")
      .swiper-button-next(ref="buttonNext", slot="button-next")
</template>

<script>
import cardComment from "./sectionComments.cardComment";

import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";

SwiperCore.use([Navigation, Pagination]);
// Import Swiper styles
import "swiper/swiper.scss";

export default {
  name: "sectionComments",
  components: {
    cardComment,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiper: null,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    };
  },
  methods: {},

};
</script>

<style lang="scss" scoped>
.swiper-container {
  padding: 0 0px;
}
.bg {
  height: 100%;
  width: 20px;
  position: absolute;
  top: 0;
  z-index: 1;
}
.container-start {
  left: 0;
  @apply bg-gradient-to-r;
  @apply from-white;
  @apply to-transparent;
}
.container-end {
  right: 0;
  @apply bg-gradient-to-l;
  @apply from-white;
  @apply to-transparent;
}

.buttons {
  position: absolute;
  z-index: 2;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.prev {
  left: -0.5rem;
}

.next {
  right: -0.5rem;
}
</style>
